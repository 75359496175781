<div class="bg-base-300 gap-3 items-center p-2" [ngClass]="{'pwa-title-bar': windowControlsOverlay(), 'flex flex-row': !windowControlsOverlay()}" [@growshrink]="isActive() && !windowControlsOverlay()">
  
  <!-- App Icon with return-to-home route -->
  <button class="flex" [routerLink]="['']" [disabled]="activeRoute() === '/'">
    <ng-icon name="zipCaptionsLogo" [size]="windowControlsOverlay() || (isActive() && !isBroadcasting()) ? '24' : '48'"></ng-icon>
  </button>
  
  <!-- App Title -->
  <div class="flex-1 draggable" *ngIf="!isBroadcasting()">
    <div class="normal-case text-xl" *ngIf="!isActive(); else smallTitle">ZipCaptions</div>
    <ng-template #smallTitle>
      <div class="normal-case text-sm">ZipCaptions.app</div>
    </ng-template>
  </div>

  <!-- Trigger recognition buttons -->
  <ng-container *ngIf="activeRoute() === '/'" [ngSwitch]="showRecordButton()">
    <app-audio-input-enable *ngSwitchCase="true"></app-audio-input-enable>
    <app-recognition-enable *ngSwitchCase="false"></app-recognition-enable>
    <button *ngIf="!isActive()" class="btn btn-circle btn-ghost tooltip tooltip-bottom" [ngClass]="{'btn-sm': windowControlsOverlay()}" [attr.data-tip]="'ROUTES.stream' | translate" routerLink="stream">
      <ng-icon name="tablerBuildingBroadcastTower"></ng-icon>
    </button>
  </ng-container>

  <!-- Broadcast Banner -->
  <ng-container *ngIf="isBroadcasting()">
    <div class="flex flex-auto flex-row justify-center">
      <span class="text-center" [ngClass]="{'text-xs': windowControlsOverlay(), 'sm:text-xl md:text-2xl': !windowControlsOverlay()}" translate>HINTS.viewStream</span>
    </div>
  </ng-container>

  <app-obs-connection-status *ngIf="showObsConnectionState()" [small]="isActive()"></app-obs-connection-status>

  <!-- Nav menu -->
  <details #menu class="dropdown dropdown-end flex z-10" *ngIf="!isActive()">
    <summary class="btn btn-circle btn-ghost text-2xl" role="button" [ngClass]="{'btn-sm': windowControlsOverlay()}" [attr.aria-label]="'LABELS.menu' | translate">
      <ng-icon name="heroBars3"></ng-icon>
    </summary>
    <div class="dropdown-content w-64">
      <ng-container *ngTemplateOutlet="routeList;context:{items: menuItems, largeText: true, topLevel: true}"></ng-container>
    </div>
  </details>

</div>

<!-- Menu template with recursion for sub-menus -->
<ng-template #routeList let-entries="items" let-largeText="largeText" let-topLevel="topLevel">
  <ul tabindex="0" class="menu menu-vertical p-2 shadow bg-primary rounded-box">
    <li [ngClass]="{'disabled': activeRoute() === item.routerOutlet}" *ngFor="let item of entries">
      
      <ng-container *ngIf="item.children; else routeButton">
        <details>
          <summary [ngClass]="{'text-lg': largeText}">{{'ROUTES.' + item.label | translate}}</summary>
          <ng-container *ngTemplateOutlet="routeList;context:{items: item.children}"></ng-container>
        </details>
      </ng-container>

      <ng-template #routeButton>
      
        <button *ngIf="item.routerOutlet; else linkButton" [routerLink]="activeRoute() === item.routerOutlet ? null : item.routerOutlet" [ngClass]="{'text-lg': largeText}">
          {{'ROUTES.' + item.label | translate }}
        </button>

        <ng-template #linkButton>
          <a [href]="item.href" target="_blank" class="flex" [ngClass]="{'text-lg': largeText}">
            <span class="flex basis-full">
              {{'ROUTES.' + item.label | translate}}
            </span>
            <ng-icon name="heroArrowTopRightOnSquare"></ng-icon>
          </a>
        </ng-template>

      </ng-template>

    </li>
    
    <li *ngIf="isLoggedIn() && topLevel">
      <button [ngClass]="{'text-lg': largeText}" (click)="logout()" translate>BUTTONS.logout</button>
    </li>
  </ul>
</ng-template>