<button #open (click)="modal.showModal()" class="tooltip tooltip-right" [attr.data-tip]="'COOKIES.MODAL.name' | translate" aria-label="Cookie Policy" *ngIf="!accepted()">
  <ng-icon name="cookie" size="24"></ng-icon>
</button>
<dialog #modal class="modal modal-bottom">
  <form method="dialog" class="modal-box bg-base-content text-base-100 sm:w-11/12 justify-self-center">
    <h3 class="font-bold text-lg">{{'COOKIES.MODAL.title' | translate}}</h3>
    <p class="text-base mt-1">
      {{'COOKIES.MODAL.p1' | translate}}
    </p>
    <p class="text-base mt-1">
      {{'COOKIES.MODAL.p2' | translate}}
    </p>
    <p class="text-base mt-1">
      {{'COOKIES.MODAL.p3' | translate}}
    </p>
    <div class="modal-action">
      <button class="btn" routerLink="cookies" [attr.aria-label]="'LABELS.viewPolicy' | translate">{{'BUTTONS.viewPolicy' | translate}}</button>
      <button class="btn" (click)="decline()" [disabled]="accepted() || !!declinedDate()" translate>
        {{ !!declinedDate() ? "BUTTONS.rejected" : "BUTTONS.reject" }}
      </button>
      <button class="btn" [ngClass]="{'btn-primary': !accepted(), 'btn-info': accepted()}" (click)="accept()" aria-label="Accept cookies">
        {{ (accepted() ? "BUTTONS.accepted" : "BUTTONS.acceptAll") | translate }} 
      </button>
    </div>
  </form>
  <form method="dialog" class="modal-backdrop">
    <button #close [attr.aria-label]="'closeCookieModal' | translate"></button>
  </form>
</dialog>
