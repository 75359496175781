<div class="drawer drawer-end overflow-y-hidden relative">
  <input #enable id="drawer-enable" type="checkbox" class="drawer-toggle" />
    <div class="drawer-content absolute bottom-0 left-0 right-0 top-0">
      <div class="flex absolute top-0 bottom-0 left-0 right-0 bg-base-100" [ngClass]="{'flex-col-reverse justify-start': textFlowDown(), 'flex-col justify-end': !textFlowDown()}">
        <app-recognized-text 
          *ngIf="renderHistory()"
          [renderHistory]="renderHistory"
          [connected]="connected"
          [hasLiveResults]="hasLiveResults"
          [textOutput]="textOutput"
          [error]="error"
          class="flex flex-grow-0 flex-shrink basis-full overflow-hidden"
        ></app-recognized-text>
        <app-recognized-live-text
          [text]="liveText" 
        ></app-recognized-live-text>
      </div>
    <label for="drawer-enable" class="drawer-button btn btn-ghost btn-half-circle-left btn-info fixed top-1/2 right-0" *ngIf="connected() || paused()" @slideInRightOnEnter @slideOutRightOnLeave>
      <ng-icon class="text-2xl" name="heroChevronLeft"></ng-icon>
    </label>
  </div>
  <div class="drawer-side" @slideOutRightOnLeave>
    <label for="drawer-enable" class="drawer-overlay opacity-0"></label>
    <app-recognition-control-sidebar></app-recognition-control-sidebar>
  </div>
</div>