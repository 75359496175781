<div class="flex flex-col basis-full p-3 items-center">
  <div class="flex flex-col max-w-lg gap-3 p-3 bg-base-300 rounded-box">
    <h1 class="text-xl w-full text-center" translate>COOKIES.POLICY.title</h1>
    <p translate>COOKIES.POLICY.p1</p>
    <div class="text-lg" translate>COOKIES.POLICY.h2</div>
    <p translate>COOKIES.POLICY.p2</p>
    <div class="text-lg" translate>COOKIES.POLICY.h3</div>
    <span class="italic" translate>COOKIES.POLICY.t3</span>
    <p translate>COOKIES.POLICY.p3</p>
    <span class="italic" translate>COOKIES.POLICY.t4</span>
    <p translate>COOKIES.POLICY.p4</p>
    <span class="italic" translate>COOKIES.POLICY.t5</span>
    <p translate>COOKIES.POLICY.p5</p>
    <div class="text-lg" translate>COOKIES.POLICY.h6</div>
    <p>
      {{'COOKIES.POLICY.p6_1' | translate}} <a href="www.internetcookies.com">www.internetcookies.com</a> {{'COOKIES.POLICY.p6_2' | translate}}
    </p>
  </div>
</div>