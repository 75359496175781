<div class="flex flex-col w-full h-full">
  <ng-container *ngIf="loading(); else loadedView">
    <div class="flex flex-col self-center items-center justify-center px-6 py-12 rounded outline-1 bg-base-300">
      <div class="text-lg">{{ 'HOME.loading' | translate}}</div>
    </div>  
  </ng-container>
  <ng-template #loadedView>
    <app-recognition-render class="flex basis-full overflow-y-hidden" *ngIf="hasResults(); else appContent"></app-recognition-render>
    <ng-template #appContent>
      <app-welcome-splash class="flex basis-full" @slideOutUpOnLeave></app-welcome-splash>
    </ng-template>
  </ng-template>
</div>