<div [@collapse]="hidden()">
  <footer class="footer bg-base-300 flex flex-auto items-center flex-row overflow-hidden px-6 py-2" >
    <div class="flex flex-row gap-6">
      <a [href]="repoUrl" target="_blank" [attr.data-tip]="'LABELS.viewGithub' | translate" class="tooltip tooltip-right">
        <ng-icon name="githubLogo" size="24"></ng-icon>
      </a>
      <a [href]="patreonUrl" target="_blank" [attr.data-tip]="'LABELS.patreon' | translate" class="tooltip tooltip-right">
        <ng-icon name="patreonLogo" size="24"></ng-icon>
      </a>
      <a [href]="discordUrl" target="_blank" [attr.data-tip]="'ROUTES.discord' | translate" class="tooltip tooltip-right">
        <ng-icon name="discordLogo" size="24"></ng-icon>
      </a>
      <app-cookie-modal></app-cookie-modal>
    </div>
    <div class="flex-auto flex-grow justify-items-center text-error text-lg">
      <span *ngIf="activeRoute$() === '/' && error$() as err" translate class="text-center">{{err}}</span>
    </div>
    <div class="flex-initial flex-row">
      <a [href]="licenseUrl" target="_blank" class="text-sm mx-4 my-2 text-base-content text-right tooltip tooltip-left tooltip-info" [attr.data-tip]="('LABELS.build' | translate) + ': ' + buildTimestamp">
        <span>{{ 'FOOTER.copyright' | translate}} {{copyrightYear}}</span> - Zip Captions
      </a>
    </div>
  </footer>
</div>